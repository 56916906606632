import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { IconButton, Stack } from "@mui/material";

const inventoryManagerId = localStorage.getItem("inventorymanagerid");
const isIManager = inventoryManagerId !== null;

const width = 160;
const disableColumnMenu = true;

const totalProColDefination = [
  {
    field: "date",
    headerName: "DATE",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "name",
    headerName: "PRODUCT NAME",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "type",
    headerName: "PRODUCT TYPE",
    headerAlign: "left",
    width,
    align: "left",
  },
  {
    field: "manufacturer",
    headerName: "MANUFACTURER",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "category",
    headerName: "CATEGORY",
    width,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "emergencytype",
    headerName: "EMERGENCY TYPE",
    width,
    align: "left",
    headerAlign: "left",
  },
];
if (!isIManager) {
  totalProColDefination.splice(1, 0, {
    field: "imname",
    headerName: "INVENTORY MANAGER",
    width,
    disableColumnMenu,
    headerAlign: "left",
    align: "left",
  });
} else {
  totalProColDefination.push({
    field: "actions",
    headerName: "ACTIONS",
    width,
    align: "left",
    renderCell: (params) => (
      <Stack direction="row" spacing={2}>
        <IconButton
          style={{
            backgroundColor: "white",
            color: "green",
            transition: "background-color 0.3s, color 0.3s",
          }}
          size="small"
          onClick={() => params.row.handleEditClick(params.row._id)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          style={{
            backgroundColor: "white",
            color: "red",
            transition: "background-color 0.3s, color 0.3s",
          }}
          size="small"
          onClick={() => params.row.handleDeleteClick(params.row._id)}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    ),
  });
}

export { totalProColDefination };
