import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridColumnMenuContainer } from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CategoryArr,
  EquipmentsArr,
  ProOrginArr,
  ProTypeArr,
} from "../Constants/constants";

export default function DataTable({
  rows,
  columns,
  rowModesModel,
  onRowModesModelChange,
  // EditToolbar,
  // setRowModesModel,
  // setRows,
  onRowsSelectionHandler,
  whichPage = null,
  colMenuValues,
  setColMenuValues,
}) {
  const navigate = useNavigate();

  function onCellClick(params) {
    if (!whichPage) return;
    if (params.field === "name") {
      if (whichPage === "avail") {
        navigate("/viewproductdetails/avail/" + params.row.productid);
      } else if (whichPage === "buffer") {
        navigate("/viewproductdetails/buff/" + params.row.productid);
      } else if (whichPage === "stockout") {
        navigate("/viewproductdetails/stockout/" + params.row.productid);
      } else if (whichPage === "entry") {
        navigate("/viewproductdetails/stockentry/" + params.row.productid);
      } else if (whichPage === "issue") {
        navigate("/viewproductdetails/stockissue/" + params.row.productid);
      } else if (whichPage === "total") {
        navigate("/viewproductdetails/total/" + params.row.productid);
      }
    }
  }

  function handleMenuChange(colDef, e) {
    const val = { ...colMenuValues };
    val[colDef.field] = e;
    setColMenuValues(val);
  }

  function CustomColumnMenu(props) {
    const { colDef } = props;

    if (colDef.field === "emergencytype") {
      return (
        <FormGroup sx={{ width: 200 }}>
          <ColMenuCheckbox
            value="All"
            colDef={colDef}
            onCheckChange={handleMenuChange}
            colMenuValues={colMenuValues}
          />
          {["Critical", "Non Critical"].map((val, i) => (
            <ColMenuCheckbox
              key={i}
              value={val}
              colDef={colDef}
              onCheckChange={handleMenuChange}
              colMenuValues={colMenuValues}
            />
          ))}
        </FormGroup>
      );
    }

    if (colDef.field === "type") {
      return (
        <FormGroup sx={{ width: 200 }}>
          <ColMenuCheckbox
            value="All"
            colDef={colDef}
            onCheckChange={handleMenuChange}
            colMenuValues={colMenuValues}
          />
          {ProTypeArr.map((val, i) => (
            <ColMenuCheckbox
              key={i}
              value={val}
              colDef={colDef}
              onCheckChange={handleMenuChange}
              colMenuValues={colMenuValues}
            />
          ))}
        </FormGroup>
      );
    }

    if (colDef.field === "category") {
      return (
        <FormGroup
          sx={{
            width: 250,
            maxHeight: 600,
            flexWrap: "nowrap",
            overflowY: "scroll",
          }}
        >
          <ColMenuCheckbox
            value="All"
            colDef={colDef}
            onCheckChange={handleMenuChange}
            colMenuValues={colMenuValues}
          />
          {CategoryArr.map((val, i) => (
            <ColMenuCheckbox
              key={i}
              value={val}
              colDef={colDef}
              onCheckChange={handleMenuChange}
              colMenuValues={colMenuValues}
            />
          ))}
        </FormGroup>
      );
    }

    return (
      <FormGroup sx={{ width: 200 }}>
        <ColMenuCheckbox
          value="All"
          colDef={colDef}
          onCheckChange={handleMenuChange}
          colMenuValues={colMenuValues}
        />
        {ProOrginArr.map((val, i) => (
          <ColMenuCheckbox
            key={i}
            value={val}
            colDef={colDef}
            onCheckChange={handleMenuChange}
            colMenuValues={colMenuValues}
          />
        ))}
      </FormGroup>
    );
  }
  return (
    <Box sx={{ height: 700, width: "100%", marginTop: "20px" }}>
      <DataGrid
        onCellClick={(p) => onCellClick(p)}
        rows={rows}
        columns={columns}
        getRowId={(row) => row._id}
        // editMode="row"
        checkboxSelection
        onRowSelectionModelChange={(id) => {
          onRowsSelectionHandler(id);
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={onRowModesModelChange}
        slots={{
          columnMenu: CustomColumnMenu,
        }}
        sx={{
          "& .MuiTablePagination-displayedRows": {
            marginTop: 0,
            marginBottom: 0,
          },
          "& .MuiTablePagination-selectLabel": {
            marginTop: 0,
            marginBottom: 0,
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            color: "#2E718A",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cellContent": {
            color: "black",
          },
        }}
      />
    </Box>
  );
}

function ColMenuCheckbox({ value, colDef, onCheckChange, colMenuValues }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          value={value}
          checked={colMenuValues[colDef.field] === value}
          onChange={(e) => onCheckChange(colDef, e.target.value)}
        />
      }
      label={value}
    />
  );
}

export function TableFilterBtn({
  anchorEl,
  onClose,
  onClick,
  columnDefinitions,
  visibleColumns,
  onChange,
}) {
  return (
    <>
      <Button
        style={{
          backgroundColor: "#2E718A",
          color: "#fff", // Ensure the text is readable
        }}
        variant="contained"
        onClick={onClick}
      >
        Filter Columns
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {columnDefinitions.map((column) => (
          <MenuItem key={column.field}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[column.field]}
                  onChange={() => onChange(column.field)}
                  color="primary"
                />
              }
              label={column.headerName}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
