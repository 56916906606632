const width = 200;
const disableColumnMenu = true;

export const columnDefinitions = [
  {
    field: "name",
    headerName: "PRODUCT NAME",
    headerAlign: "left",
    width,
    align: "left",
    disableColumnMenu,
  },

  {
    field: "type",
    headerName: "PRODUCT TYPE",
    headerAlign: "left",
    width,
    align: "left",
  },
  {
    field: "manufacturer",
    headerName: "MANUFACTURER",
    width,
    align: "left",
    disableColumnMenu,
  },
  {
    field: "category",
    headerName: "CATEGORY",
    width,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "totalquantity",
    headerName: "TOTAL QUANTITY",
    width,
    align: "left",
    headerAlign: "left",
    disableColumnMenu,
  },
  {
    field: "emergencytype",
    headerName: "EMERGENCY TYPE",
    width,
    align: "left",
    headerAlign: "left",
  },
];

if (localStorage.getItem("inventorymanagerid") === null) {
  columnDefinitions.splice(1, 0, {
    field: "imname",
    headerName: "INVENTORY MANAGER",
    width,
    align: "left",
    headerAlign: "left",
    disableColumnMenu,
  });
}
