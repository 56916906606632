export const ProTypeArr = ["Pharmaceuticals", "Consumables", "Equipments"];

export const ProOrginArr = ["USA", "Korea", "India", "Australia"];

export const CategoryArr = [
  "Cardiovascular",
  "Hormones",
  "Inhalable",
  "Oral",
  "Topical",
  "Amino",
  "Probiotics",
  "Skincare",
  "Supplements",
  "Vitamins",
  "Ayurvedic",
  "Herbal",
  "Herbals",
  "Catheters",
  "Dental",
  "Infection",
  "Laboratory",
  "Radiology",
  "Respiratory",
  "Surgical",
  "Wound",
  "Bed",
  "Seating",
  "Storage",
  "Diagnostic Furniture",
  "Patient Room Furniture",
  "Rehabilitation Furniture",
  "Laboratory Furniture",
  "Waiting Area Furniture",
  "Surgical Room Furniture",
  "Privacy Furniture",
  "Diagnostic Instruments",
  "Surgical Instruments",
  "Endoscopy Instruments",
  "Laparoscopy Instrument",
  "Orthopedic Instruments",
  "Dental Instruments",
  "Gynecological and Obstetric Instruments",
  "Cardiac Instruments",
  "Microsurgical Instruments",
  "Neurosurgical Instruments",
  "Urological Instruments",
  "Diagnostic Equipment",
  "Monitoring Equipment",
  "Laparoscopy Equipment",
  "Therapeutic Equipment",
  "Surgical Equipment",
  "Rehabilitation Equipment",
  "Patient Care Equipment",
  "Laboratory Equipment",
  "Emergency Medical Equipment",
  "Radiation Therapy Equipment",
  "Catheters and Tubes",
  "Dental Consumables",
  "Infection Control Consumables",
  "Laboratory Consumables",
  "Radiology Consumables",
  "Respiratory Consumables",
  "Surgical Consumables",
  "Wound Care Supplies",
  "Stationary",
  "Injectable",
  "Drop",
];
